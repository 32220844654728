import REBSCarousel from '../components/rebs-carousel';
import SectionManager from './section-manager';

export default class TestimonialsSection {
  constructor( element ) {
    this.element = element;
    this.initialize();
  }

  initialize() {
    new REBSCarousel($(this.element).find('.rebs-carousel').get(0), {
      keyboardSlide: false,
      enableFullscreen: false,
      autoPlay: $(this.element).data('autoplay') === 'True',
      pagination: true,
    });
  }
}

let manager = new SectionManager();
manager.subscribe(".section-testimonials-carousel", TestimonialsSection);
