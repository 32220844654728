// Lightweight wrapper for carousels
import $ from 'jquery';

// import Swiper
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import 'tocca';

const CAROUSEL_ACTIONS_SELECTOR = '.carousel-buttons a';
const FULLSCREEN_SELECTOR = '.carousel-fullscreen';
const FULLSCREEN_EXIT_SELECTOR = '.carousel-fullscreen-exit';
const FULLSCREEN_PICTURE_SELECTOR = 'picture > .fullscreen-picture';
const THUMBNAIL_PICTURE_SELECTOR = 'picture > .thumbnail-picture'
const SWIPERJS_OPTIONS = {
  loop: true,
  loopedSlides: 2,
  // Prevent swiping from interferiing with taps
  threshold: 5,
  centeredSlides: true,
  slidesPerView: 1,
  // Fixes issues with fractional pixels cause by bootstrap's %-based grid
  roundLengths: true,
  spaceBetween: 1,
  simulateTouch: false,
  pagination: {
    el: '.carousel-page',
    type: 'fraction',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};
const DEFAULT_OPTIONS = {
  keyboardSlide: false,
  enableFullscreen: true,
  autoPlay: false,
  pagination: false,
};

export default class REBSCarousel {
   constructor( element, options ) {
    this.element = element;
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.swiper = this.initialize();
    this.addEventHandlers();
   }

   initialize() {
    let swiperOptions = Object.assign({}, SWIPERJS_OPTIONS, {
      keyboard: {
        enabled: this.options.keyboardSlide,
      },
      autoplay: this.options.autoPlay ? {
        delay: 5 * 1000, // 5 seconds in ms
      } : false,
      pagination: this.options.pagination ? {
        el: '.swiper-pagination',
      } : SWIPERJS_OPTIONS.pagination,
    });
    $(this.element).addClass('swiper-container');
    let swiper = new Swiper(this.element, swiperOptions);
    return swiper;
   }

   /**
    * Enters or exits fullscreen mode
    * @param  {Boolean} enable If true, enter fullscreen mode, othweriwse exit it
    */
   toggleFullscreen(enable=true) {
    let $el = $(this.element),
        $body = $('html');

    if (enable) {
      // Enter fullscreen
      if ($el.hasClass('is-fullscreen')) {
        return;
      }
      this.upgradeImagesToFullscreen();
      this.swiper.keyboard.enable();
      $el.addClass('is-fullscreen');
      $body.addClass('overflow-hidden')
           .addClass('backdrop');
    } else {
      // Exit fullsceen
      $el.removeClass('is-fullscreen');
      this.downgradeImagesToThumbnail();
      // If keyboard sliding is always on, don't disable it
      if (!this.options.keyboardSlide) {
        this.swiper.keyboard.disable();
      }
      $body.removeClass('overflow-hidden')
           .removeClass('backdrop');
    }
    this.update();
   }

   /**
    * Upgrade images in the carousel that provide a path to their full resolution
    */
   upgradeImagesToFullscreen() {
    $(this.element).find(FULLSCREEN_PICTURE_SELECTOR).removeClass('d-none');
    $(this.element).find(THUMBNAIL_PICTURE_SELECTOR).addClass('d-none');
   }

   /**
    * Downgrade images from fullscreen to thumbnails.
    */
   downgradeImagesToThumbnail() {
    $(this.element).find(FULLSCREEN_PICTURE_SELECTOR).addClass('d-none');
    $(this.element).find(THUMBNAIL_PICTURE_SELECTOR).removeClass('d-none');
   }

   addEventHandlers() {
      if (this.options.enableFullscreen) {
        let $carouselActions,
            $fullscreen,
            $fullscreenExit;

        // Fullscreen
        $fullscreen = $(this.element).find(FULLSCREEN_SELECTOR);
        $fullscreen.on('click', (event) => {
          this.toggleFullscreen();
        });

        // Touch-based fullscreen
        $(this.element).on('tap', '.swiper-slide-active', (event) => {
          this.toggleFullscreen();
        });

        // Exit fullscreen
        $fullscreenExit = $(this.element).find(FULLSCREEN_EXIT_SELECTOR);
        $fullscreenExit.on('click', (event) => {
          this.toggleFullscreen(false);
        });

        // Exit fullscreen on any carousel button clicking
        $carouselActions = $(this.element).find(CAROUSEL_ACTIONS_SELECTOR);
        $carouselActions.on('click', (event) => {
          this.toggleFullscreen(false);
        });

        // Exit fullscreen on escape
        $(document).keyup((e) => {
          if (e.key === "Escape") {
            this.toggleFullscreen(false);
          }
        });
      }
   }

   update() {
      this.swiper.update();
      $(this.element).trigger($.Event('rebs.carousel.update'));
   }
}
